import React from 'react';
import cln from 'classnames';

import styles from './ChangeBox.module.css';
import useTranslate from 'hooks/useTranslate';

type Props = {
  classes?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string;
};

const ChangeBox = (props: Props) => {
  const { classes, disabled = false, onChange, value } = props;

  const { name, getText } = useTranslate('', '');

  const handleChange = ({ target }: any) => {
    const { value } = target;

    onChange(value.replace(/\D/g, ''));
  };

  return (
    <div className={cln(styles.wrapperChangeBox, classes)}>
      <div className={styles.title}>
        {getText(disabled ? 'change.enterWillChange' : 'change.howManyReceive')}
      </div>

      <input
        className={styles.input}
        disabled={disabled}
        value={value}
        placeholder={getText(
          disabled ? 'change.placeholderReceive' : 'change.placeholderChange',
        )}
        onChange={handleChange}
      />

      <div className={styles.description}>
        {getText(disabled ? 'change.notice1' : 'change.notice2')}
      </div>
    </div>
  );
};

export default ChangeBox;
