import { EMessageType } from 'types/messages';
import styles from './CoinFarm.module.css';
import { useState } from 'react';
import { getBalanceStepSelector } from 'store/balance/selectors';
import { useZustandBalanceStore } from 'store/balance/store';
import EnergyInfo from './components/EnergyInfo';
import BoostEnergy from './components/BoostEnergy';
import { useZustandEnergyStore } from 'store/energy/store';
import { getCurrentEnergySelector } from 'store/energy/selectors';
import CircularFarm from './components/CircleFarm';
import { useZustandHeroStore } from 'store/hero/store';
import { getHeroBoostOddSelector } from 'store/hero/selectors';
import { getRandomArbitrary } from 'utils/shared';

const CoinFarm = () => {
  const [clicks, setClicks] = useState<{ id: number; x: number; y: number }[]>(
    [],
  );

  const currentEnergy = useZustandEnergyStore(getCurrentEnergySelector);

  const boost = useZustandHeroStore(getHeroBoostOddSelector);

  const balanceStep = useZustandBalanceStore(getBalanceStepSelector);

  const handleChangeBalance = (e: React.MouseEvent<HTMLDivElement>) => {
    handleCardClick(e);

    window.parent.postMessage(
      { type: EMessageType.IsTapped, payload: true },
      '*',
    );
  };
  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!Math.floor(currentEnergy)) {
      return;
    }
    // const card = e.currentTarget;

    // const rect = card.getBoundingClientRect();

    // const diffHorizontal = e.clientX - rect.left;
    // const diffVertical = e.clientY - rect.top;

    // const diffWidth = rect.width / 2;
    // const diffHeight = rect.height / 2;

    // const x = diffHorizontal - diffWidth;

    // const y = diffVertical - diffHeight;

    // card.style.transform = `perspective(1000px) rotateX(${-y / 10}deg) rotateY(${x / 10}deg)`;

    // setTimeout(() => {
    //   card.style.transform = '';
    // }, 50);

    setClicks([
      ...clicks,
      {
        id: Date.now(),
        x: e.pageX - getRandomArbitrary(30, 220),
        y: e.pageY - getRandomArbitrary(30, 220),
      },
    ]);
  };

  const handleAnimationEnd = (id: number) => () => {
    setClicks((prevClicks) => prevClicks.filter((click) => click.id !== id));
  };

  return (
    <>
      <div className={styles.wrapperHamster} onClick={handleChangeBalance}>
        <CircularFarm />

        {clicks.map(({ id, x, y }) => (
          <div
            key={id}
            className={styles.point}
            style={{
              top: `${y}px`,
              left: `${x}px`,
            }}
            onAnimationEnd={handleAnimationEnd(id)}
          >
            +{balanceStep} {boost > 1 ? `x${boost}` : ''}
          </div>
        ))}
        {clicks.map(({ id, x, y }) => (
          <div className={styles.pulsing} key={id} />
        ))}
      </div>

      <EnergyInfo />

      <BoostEnergy />
    </>
  );
};

export default CoinFarm;
