import { create } from 'zustand';
import { ENavBarType, ZustandUiStore } from './types';

export const useZustandUiStore = create<ZustandUiStore>((set) => ({
  navBarType: ENavBarType.Base,

  setNavBarType: (navBarType) => {
    set({ navBarType });
  },
}));
