import { create } from 'zustand';
import { ZustandEnergyStore } from './types';

export const useZustandEnergyStore = create<ZustandEnergyStore>((set) => ({
  currentEnergy: 0,
  dailyEnergy: 0,

  setCurrentEnergy: (currentEnergy) => {
    set({ currentEnergy });
  },

  setDailyEnergy: (dailyEnergy) => {
    set({ dailyEnergy });
  },
}));
