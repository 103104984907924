import { ZustandLevelsStore } from './types';

export const getListOfLevelsSelector = (state: ZustandLevelsStore) =>
  state.listOfLevels;

export const getCurrentLevelSelector = (state: ZustandLevelsStore) =>
  state.currentLevel;

// ACTIONS

export const setListOfLevelsAction = (state: ZustandLevelsStore) =>
  state.setListOfLevels;

export const setCurrentLevelAction = (state: ZustandLevelsStore) =>
  state.setCurrentLevel;
