import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import cln from 'classnames';

import Text from 'components/Text';

import { useZustandGameShopStore } from 'store/gameShop/store';
import { getShopDataInfoByIdSelector } from 'store/gameShop/selectors';
import { ShopAvatar, ShopData, ShopHero } from 'store/gameShop/types';

import { EMessageType } from 'types/messages';

import { useZustandBalanceStore } from 'store/balance/store';
import { getBalanceSelector } from 'store/balance/selectors';

import styles from './SectionStore.module.css';
import { ERouteNames } from 'routes/types';

type Props = {
  storeKey: keyof ShopData;
  classes?: string;
};

const SectionStore = ({ storeKey, classes }: Props) => {
  const data = useZustandGameShopStore(getShopDataInfoByIdSelector(storeKey));

  const balance = useZustandBalanceStore(getBalanceSelector);

  const navigate = useNavigate();

  const handleBuyAvatar = (payload: ShopAvatar) => () => {
    window.parent.postMessage(
      {
        type: EMessageType.BuyAvatarItem,
        payload,
      },
      '*',
    );
  };
  const handleBuyHero = (payload: ShopHero) => () => {
    navigate(ERouteNames.Home);
    window.parent.postMessage(
      {
        type: EMessageType.BuyHeroItem,
        payload,
      },
      '*',
    );
  };

  const handleFn = useMemo(() => {
    switch (storeKey) {
      case 'avatars':
        return handleBuyAvatar;
      case 'heroes':
        return handleBuyHero;
      default:
        return undefined;
    }
  }, [storeKey]);

  if (!data?.length) {
    return null;
  }

  return (
    <div className={cln(styles.wrapperSectionStore, classes)}>
      <div className={styles.title}>
        <Text idT={`craft.${storeKey}`} />
      </div>

      <div
        className={cln(styles.row, {
          [styles.disable]: storeKey === 'promoCodes',
        })}
      >
        {data?.map((i) => (
          <div
            key={i.id}
            className={cln(styles.item, {
              [styles.hero]: storeKey === 'heroes',
              [styles.disable]: balance < i.price,
            })}
            // @ts-ignore
            onClick={handleFn?.(i)}
          >
            <img src={i.path} className={styles.image} />

            <div className={styles.price}>{i.price}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStore;
