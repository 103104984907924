import { ZustandEnergyStore } from './types';

export const getCurrentEnergySelector = (state: ZustandEnergyStore) =>
  state.currentEnergy;

export const getDailtyEnergySelector = (state: ZustandEnergyStore) =>
  state.dailyEnergy;

// ACTIONS

export const setCurrentEnergyAction = (state: ZustandEnergyStore) =>
  state.setCurrentEnergy;

export const setDailyEnergyAction = (state: ZustandEnergyStore) =>
  state.setDailyEnergy;
