import { create } from 'zustand';
import { ZustandLevelsStore } from './types';

export const useZustandLevelsStore = create<ZustandLevelsStore>((set) => ({
  listOfLevels: [],
  currentLevel: 0,

  setListOfLevels: (listOfLevels) => {
    set({ listOfLevels });
  },

  setCurrentLevel: (currentLevel) => {
    set({ currentLevel });
  },
}));
