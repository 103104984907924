import { create } from 'zustand';
import { ZustandAuthStore } from './types';

export const useZustandAuthStore = create<ZustandAuthStore>((set) => ({
  isAuth: false,

  setAuth: (isAuth) => {
    set({ isAuth });
  },
}));
