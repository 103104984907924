import styles from './ProgressBar.module.css';

type Props = {
  percent: number;
};

const ProgressBar = ({ percent }: Props) => (
  <div className={styles.wrapperProgressBar}>
    <div className={styles.progressbar}></div>
    <div className={styles.highlight} style={{ width: `${percent}%` }}></div>
  </div>
);

export default ProgressBar;
