import { useEffect, useState } from 'react';
import { EMessageType } from 'types/messages';

import styles from './BoostEnergy.module.css';
import Text from 'components/Text';

const BoostEnergy = () => {
  const [pause, setPause] = useState(0);

  const handleBoost = () => {
    setPause(30);

    window.parent.postMessage(
      { type: EMessageType.BoostEnergy, payload: true },
      '*',
    );
  };

  useEffect(() => {
    if (!pause) {
      return;
    }

    const timer = setTimeout(() => {
      setPause(pause - 1);
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [pause]);

  return (
    <div
      className={styles.wrapperBoostButton}
      onClick={pause ? undefined : handleBoost}
    >
      {pause ? (
        <>
          {pause}
          <Text idT="waiting" />
        </>
      ) : (
        <Text idT="getEnergy" />
      )}
    </div>
  );
};

export default BoostEnergy;
