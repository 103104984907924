import cln from 'classnames';

import { ReactComponent as Coin } from 'assets/coin.svg';

import { formatBalance } from 'utils/shared';

import styles from './Balance.module.css';
import { useZustandBalanceStore } from 'store/balance/store';
import { getBalanceSelector } from 'store/balance/selectors';

const Balance = ({ classes }: { classes?: string }) => {
  const balance = useZustandBalanceStore(getBalanceSelector);

  return (
    <div className={cln(styles.balance, classes)}>
      <Coin className={styles.size} />

      <div className={styles.big}>{formatBalance(balance)} </div>
    </div>
  );
};

export default Balance;
