import ProgressBar from 'components/ui/ProgressBar';
import { ReactComponent as Arrow } from 'assets/arrow.svg';

import { useZustandLevelsStore } from 'store/levels/store';
import { getListOfLevelsSelector } from 'store/levels/selectors';

import { useZustandBalanceStore } from 'store/balance/store';
import { getBalanceSelector } from 'store/balance/selectors';

import styles from './Progress.module.css';
import { useMemo } from 'react';
import Text from 'components/Text';

const Progress = () => {
  const balance = useZustandBalanceStore(getBalanceSelector);

  const listOfLevels = useZustandLevelsStore(getListOfLevelsSelector);

  const { percent, nextLvl } = useMemo(() => {
    const nextLvl = listOfLevels.find((l) => l.minCoins > balance);

    if (!nextLvl) {
      return { nextLvl: listOfLevels.at(-1), percent: 100 };
    }

    return { percent: (balance * 100) / nextLvl.minCoins, nextLvl };
  }, [balance, listOfLevels]);

  return (
    <div className={styles.wrapperProgress}>
      <div className={styles.levelInfo}>
        <div className={styles.section}>
          <div className={styles.text}>{(nextLvl?.level || 1) - 1}</div>
          <Arrow />
        </div>

        <div className={styles.section}>
          <div className={styles.level}>
            <Text idT="level" />
          </div>

          <div className={styles.text}>
            {(nextLvl?.level || 1) - 1}/{nextLvl?.level}
          </div>
        </div>
      </div>

      <ProgressBar percent={percent} />
    </div>
  );
};

export default Progress;
