import { ZustandBalanceStore } from './types';

export const getBalanceSelector = (state: ZustandBalanceStore) => state.balance;
export const getBalanceStepSelector = (state: ZustandBalanceStore) =>
  state.balanceStep;

// ACTIONS

export const setBalanceAction = (state: ZustandBalanceStore) =>
  state.setBalance;

export const setBalanceStepAction = (state: ZustandBalanceStore) =>
  state.setBalanceStep;
