import { useEffect, useState } from 'react';

import Loader from 'components/Loader';

import { useZustandBalanceStore } from 'store/balance/store';
import {
  setBalanceAction,
  setBalanceStepAction,
} from 'store/balance/selectors';
import { useZustandLevelsStore } from 'store/levels/store';
import {
  setCurrentLevelAction,
  setListOfLevelsAction,
} from 'store/levels/selectors';
import { useZustandAuthStore } from 'store/auth/store';
import { setAuthAction } from 'store/auth/selectors';
import { useZustandEnergyStore } from 'store/energy/store';
import {
  setCurrentEnergyAction,
  setDailyEnergyAction,
} from 'store/energy/selectors';

import { EMessageType } from 'types/messages';

import { useZustandGameShopStore } from 'store/gameShop/store';
import { setShopDataAction } from 'store/gameShop/selectors';
import { useZustandHeroStore } from 'store/hero/store';
import { setHeroInfoAction } from 'store/hero/selectors';

import styles from './HOCInitial.module.css';
import { useZustandLanguagesStore } from 'store/languages/store';
import { getAndSetLanguagesAction } from 'store/languages/selectors';
import { getValueByIdFromUrl } from 'utils/shared';
import Text from 'components/Text';

const isMobile = window.innerWidth < 768;

const HOCInitial = ({ children }: { children: React.ReactElement }) => {
  const setBalance = useZustandBalanceStore(setBalanceAction);

  const setBalanceStep = useZustandBalanceStore(setBalanceStepAction);

  const setAuth = useZustandAuthStore(setAuthAction);

  const setCurrentEnergy = useZustandEnergyStore(setCurrentEnergyAction);

  const setDailyEnergy = useZustandEnergyStore(setDailyEnergyAction);

  const setListOfLevels = useZustandLevelsStore(setListOfLevelsAction);

  const setShopData = useZustandGameShopStore(setShopDataAction);

  const setCurrentLevel = useZustandLevelsStore(setCurrentLevelAction);

  const setHeroInfo = useZustandHeroStore(setHeroInfoAction);

  const getAndSetLanguages = useZustandLanguagesStore(getAndSetLanguagesAction);

  const [isLoading, setLoading] = useState(true);

  const updateEnergy = (energy: number) => {
    try {
      setCurrentEnergy(energy);
    } catch (error) {
      console.warn('~ error in setting energy', error);
    }
  };

  const updateBalance = (balance: number) => {
    try {
      setBalance(balance);
    } catch (error) {
      console.warn('~ error in setting balance', error);
    }
  };

  const bulkData = (data: any) => {
    try {
      const {
        isAuth,
        balance,
        balanceStep,
        currentEnergy,
        dailyEnergy,
        levels,
        currentLevel,
        ...rest
      } = data;

      setAuth(isAuth);
      setBalance(balance);
      setBalanceStep(balanceStep);
      setCurrentEnergy(currentEnergy);
      setDailyEnergy(dailyEnergy);
      setListOfLevels(levels);
      setCurrentLevel(currentLevel);
      setHeroInfo(rest);

      setLoading(false);

      window?.parent?.postMessage(
        {
          type: EMessageType.InitValuesStatus,
          payload: {
            status: true,
          },
        },
        '*',
      );
    } catch (error) {
      window?.parent?.postMessage(
        {
          type: EMessageType.InitValuesStatus,
          payload: {
            status: false,
          },
        },
        '*',
      );
    }
  };

  const updateTheme = ({
    themes,
  }: {
    themes: { key: string; value: string }[];
  }) => {
    try {
      const root = document.querySelector<HTMLElement>(':root');

      if (!root) {
        throw new Error('No root element');
      }
      themes.forEach((color) => {
        root.style.setProperty(color.key, color.value);
      });

      window?.parent?.postMessage(
        {
          type: EMessageType.ThemeStatus,
          payload: {
            status: true,
          },
        },
        '*',
      );
    } catch (error) {
      window?.parent?.postMessage(
        {
          type: EMessageType.ThemeStatus,
          payload: {
            status: false,
          },
        },
        '*',
      );
    }
  };

  const handleParse = (e: MessageEvent) => {
    try {
      const { payload, type } = e.data;

      switch (type) {
        case EMessageType.Energy:
          return updateEnergy(payload.energy);

        case EMessageType.Theme:
          return updateTheme(payload);

        case EMessageType.Balance:
          return updateBalance(payload.balance);

        case EMessageType.InitValues:
          return bulkData(payload);
        case EMessageType.StoreData:
          return setShopData(payload);
        default:
          break;
      }
    } catch (error) {
      console.warn(
        'data retrieval error',
        error,
        'Failed to parse JSON for:',
        e.data,
      );
    }
  };

  useEffect(() => {
    window?.parent?.postMessage({ type: 'app-loaded', payload: true }, '*');

    window.addEventListener('message', handleParse, true);

    const lang = getValueByIdFromUrl('lang', window.location.href) || 'en';

    getAndSetLanguages(lang);

    return () => {
      window.removeEventListener('message', handleParse, true);
    };
  }, []);

  if (!isMobile) {
    return (
      <div className={styles.unavailable}>
        <Text idT="unavailable" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>{isLoading ? <Loader /> : children}</div>
  );
};

export default HOCInitial;
