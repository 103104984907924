import React from 'react';

import NavBar from 'components/NavBar';

import styles from './NavBarLayout.module.css';

type Props = {
  children: React.ReactElement;
};

const NavBarLayout = ({ children }: Props) => (
  <>
    <div className={styles.layoutWrapper}>{children}</div>

    <NavBar />
  </>
);

export default NavBarLayout;
