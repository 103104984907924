import { Route, Routes } from 'react-router-dom';

import Home from 'pages/Home';
import Craft from 'pages/Craft';

import { ERouteNames } from './types';
import Change from 'pages/Change';
import Rules from 'pages/Rules';

const InitialRoutes = () => (
  <Routes>
    <Route path={ERouteNames.Home} element={<Home />} />

    <Route path={ERouteNames.Craft} element={<Craft />} />

    <Route path={ERouteNames.Change} element={<Change />} />

    <Route path={ERouteNames.Rules} element={<Rules />} />
  </Routes>
);

export default InitialRoutes;
