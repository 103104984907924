import { useEffect } from 'react';

import SectionStore from 'components/SectionStore';
import Balance from 'components/ui/Balance';

import { useZustandGameShopStore } from 'store/gameShop/store';
import { getShopDataInfoSelector } from 'store/gameShop/selectors';
import { ShopData } from 'store/gameShop/types';

import { EMessageType } from 'types/messages';
import styles from './Craft.module.css';
import Text from 'components/Text';

const Craft = () => {
  const shop = useZustandGameShopStore(getShopDataInfoSelector);

  useEffect(() => {
    window?.parent?.postMessage(
      {
        type: EMessageType.GetStoreLinks,
        payload: {
          status: true,
        },
      },
      '*',
    );
  }, []);

  return (
    <div className={styles.wrapperCraft}>
      <Balance />

      {shop ? (
        <div>
          {Object.keys(shop).map((sKey) => (
            <SectionStore
              key={sKey}
              storeKey={sKey as keyof ShopData}
              classes={styles.marginB}
            />
          ))}
        </div>
      ) : (
        <div>
          <Text idT="craft.loading" />
        </div>
      )}
    </div>
  );
};

export default Craft;
