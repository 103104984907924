import React from 'react';

import styles from './Rules.module.css';
import { RULES } from './constants';
import Text from 'components/Text';

const Rules = () => (
  <div className={styles.wrapperRules}>
    {RULES.map((r) => (
      <div className={styles.section} key={r.title}>
        <div className={styles.title}>
          <Text idT={r.title} />
        </div>
        <div className={styles.description}>
          {r.options.map((o) => (
            <div className={styles.block} key={o}>
              <Text idT={o} />
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default Rules;
