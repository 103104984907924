import HOCInitial from 'components/HOCs/HOCInitial';
import NavBarLayout from 'components/Layouts/NavBarLayout';
import InitialRoutes from 'routes';

const App = () => (
  <HOCInitial>
    <NavBarLayout>
      <InitialRoutes />
    </NavBarLayout>
  </HOCInitial>
);

export default App;
