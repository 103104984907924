import { create } from 'zustand';
import { ZustandGameShopStore } from './types';

export const useZustandGameShopStore = create<ZustandGameShopStore>((set) => ({
  data: null,

  setShopData: (data) => {
    set({ data });
  },
}));
