import { create } from 'zustand';
import { ZustandHeroStore } from './types';

export const useZustandHeroStore = create<ZustandHeroStore>((set) => ({
  hero: null,
  boost: 1,
  path: '',

  setInfo: ({ hero, boost, path }) => {
    set({ hero, boost, path });
  },
}));
