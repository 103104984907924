import { ZustandHeroStore } from './types';

export const getHeroPathSelector = (store: ZustandHeroStore) => store.path;

export const getHeroBoostOddSelector = (store: ZustandHeroStore) => store.boost;

export const getHeroIdSelector = (store: ZustandHeroStore) => store.hero;

// Actions

export const setHeroInfoAction = (store: ZustandHeroStore) => store.setInfo;
