import { create } from 'zustand';

import { ZustandBalanceStore } from './types';

export const useZustandBalanceStore = create<ZustandBalanceStore>((set) => ({
  balance: 0,
  balanceStep: 0,

  setBalance: (balance) => {
    set({ balance });
  },
  setBalanceStep: (balanceStep) => {
    set({ balanceStep });
  },
}));
