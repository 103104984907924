import { useState } from 'react';
import cln from 'classnames';

import ChangeBox from 'components/ui/ChangeBox';
import Balance from 'components/ui/Balance';
import { useZustandBalanceStore } from 'store/balance/store';
import { getBalanceSelector } from 'store/balance/selectors';
import { EMessageType } from 'types/messages';

import styles from './Change.module.css';
import { wackyRound } from 'utils/shared';
import Text from 'components/Text';

const RATIO = {
  min: 0.001,
  max: 0.1,
};
const ratioDiff = RATIO.max - RATIO.min;

const randomDiff = Math.random() * ratioDiff;

const Change = () => {
  const balance = useZustandBalanceStore(getBalanceSelector);

  const [coins, setCoins] = useState('');

  const [casinoCoins, setCasinoCoins] = useState(0);

  const [ratio] = useState(() => (randomDiff + RATIO.min).toFixed(4));

  const onChange = (v: string) => {
    if (!+v) {
      setCoins('');
      setCasinoCoins(0);

      return;
    }

    if (+v > balance) {
      return;
    }
    setCoins(`${v}`);
    setCasinoCoins(+wackyRound(+v * +ratio, 2));
  };

  const handleChange = () => {
    window.parent.postMessage(
      {
        type: EMessageType.ChangeCoins,
        payload: {
          coins,
          casinoCoins,
        },
      },
      '*',
    );
    setCoins('');
    setCasinoCoins(0);
  };

  return (
    <div className={styles.wrapperChange}>
      <div className={styles.title}>
        <Text idT="change.title" />
      </div>

      <Balance classes={styles.marginB} />

      <div className={cln(styles.wrapperForm, styles.marginB)}>
        <ChangeBox classes={styles.height} onChange={onChange} value={coins} />

        <div className={styles.changeArrows}>
          1 <Text idT="change.coin" /> = {wackyRound(1 * +ratio, 2)}
        </div>

        <ChangeBox
          classes={styles.height}
          disabled
          onChange={console.info}
          value={`${casinoCoins}`}
        />
      </div>
      <div
        className={cln(styles.changeBtn, {
          [styles.disable]: !casinoCoins,
        })}
        onClick={handleChange}
      >
        <Text idT="change.exchange" />
      </div>
    </div>
  );
};

export default Change;
