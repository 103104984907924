import cln from 'classnames';

import { useZustandBalanceStore } from 'store/balance/store';
import {
  getBalanceSelector,
  getBalanceStepSelector,
} from 'store/balance/selectors';
import { useZustandLevelsStore } from 'store/levels/store';
import {
  getCurrentLevelSelector,
  getListOfLevelsSelector,
} from 'store/levels/selectors';

import styles from './UserProfit.module.css';
import { useMemo } from 'react';
import Text from 'components/Text';

type Props = {
  classes?: string;
};

const UserInfo = ({ classes }: Props) => {
  const balanceStep = useZustandBalanceStore(getBalanceStepSelector);

  const currentLevel = useZustandLevelsStore(getCurrentLevelSelector);

  const balance = useZustandBalanceStore(getBalanceSelector);

  const listOfLevels = useZustandLevelsStore(getListOfLevelsSelector);

  const { percent, nextLvl } = useMemo(() => {
    const nextLvl = listOfLevels.find((l) => l.minCoins > balance);

    if (!nextLvl) {
      return { nextLvl: listOfLevels.at(-1), percent: 100 };
    }

    return { percent: (balance * 100) / nextLvl.minCoins, nextLvl };
  }, [balance, listOfLevels]);

  return (
    <div className={cln(styles.profit, classes)}>
      <div className={styles.cardLayout}>
        <div>
          <div>
            <span className={styles.lvl}>{currentLevel}</span>
            <span className={styles.textLvl}>
              <Text idT="level" />
            </span>
          </div>
          <div>
            <Text idT="coinPerTap" /> {balanceStep}
          </div>
        </div>

        <div className={styles.circularProgress}>{Math.floor(percent)}%</div>
      </div>

      {/* <div className={cln(styles.cardLayout, styles.change)}>exchange</div> */}
    </div>
  );
};

export default UserInfo;
