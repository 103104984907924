import { NavLink } from 'react-router-dom';
import cln from 'classnames';

import { NAV_BAR } from './constants';

import { useZustandUiStore } from 'store/ui/store';
import { getNavBarTypeSelector } from 'store/ui/selectors';

import { ENavBarType } from 'store/ui/types';

import styles from './NavBar.module.css';

const NavBar = () => {
  const navBarType = useZustandUiStore(getNavBarTypeSelector);

  return (
    <div
      className={cln(styles.wrapperNavBar, {
        [styles.hidden]: navBarType === ENavBarType.Hidden,
      })}
    >
      <div className={styles.navigation}>
        {NAV_BAR.map(({ icon, path, title }, idx) => (
          <NavLink
            to={path}
            key={idx}
            className={({ isActive }) =>
              cln(styles.navigationButton, {
                [styles.activeNav]: isActive,
              })
            }
          >
            {icon}

            <div>{title}</div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default NavBar;
