// export const getShop = (state: ZustandAuthStore) => state.isAuth;

import { ShopData, ZustandGameShopStore } from './types';

export const getShopDataInfoSelector = (state: ZustandGameShopStore) =>
  state.data;

export const getShopDataInfoByIdSelector =
  (id: keyof ShopData) => (state: ZustandGameShopStore) => {
    const shopData = getShopDataInfoSelector(state);

    if (!shopData) {
      return null;
    }

    return shopData[id] || null;
  };

// ACTIONS
export const setShopDataAction = (state: ZustandGameShopStore) =>
  state.setShopData;
