export interface ZustandUiStore extends ZustandUiStoreEntities {
  setNavBarType: (d: ENavBarType) => void;
}

interface ZustandUiStoreEntities {
  navBarType: ENavBarType;
}

export enum ENavBarType {
  Base,
  Hidden,
}
