export const formatBalance = (balance: number) => {
  const options = { useGrouping: true, minimumFractionDigits: 0 };

  return balance.toLocaleString('en-US', options);
};

export const wackyRound = (number: number, places: number) => {
  const [int, float] = `${number}`.split('.');

  if (!float) {
    return int;
  }

  return `${int}.${float[0] || 0}${float[1] || 0}`;
};

export const getValueByIdFromUrl = (key: string, path: string): string => {
  const url = new URL(path);

  const value = url.searchParams.get(key) || '';

  return value;
};

export const getRandomArbitrary = (min: number, max: number) => {
  const base = Math.random() * (max - min);

  return base + min;
};
