import CoinFarm from '../../components/CoinFarm';
import Progress from '../../components/Progress';
import UserInfo from 'components/UserProfit';
import Balance from 'components/ui/Balance';

import styles from './Home.module.css';

const Home = () => (
  <div className={styles.wrapperHome}>
    <div className={styles.content}>
      <UserInfo />

      <Progress />

      <Balance classes={styles.marginB} />

      <CoinFarm />
    </div>
  </div>
);

export default Home;
