import { ReactComponent as PowerSvg } from 'assets/power.svg';

import {
  getCurrentEnergySelector,
  getDailtyEnergySelector,
} from 'store/energy/selectors';
import { useZustandEnergyStore } from 'store/energy/store';

import styles from './EnergyInfo.module.css';

const EnergyInfo = () => {
  const currentEnergy = useZustandEnergyStore(getCurrentEnergySelector);

  const dailyEnergy = useZustandEnergyStore(getDailtyEnergySelector);

  return (
    <div className={styles.powerContent}>
      <div className={styles.power}>
        <PowerSvg />

        <div>
          {Math.floor(currentEnergy)} / {dailyEnergy}
        </div>
      </div>
    </div>
  );
};

export default EnergyInfo;
