import React from 'react';

import styles from './CircularForm.module.css';
import { useZustandHeroStore } from 'store/hero/store';
import { getHeroIdSelector, getHeroPathSelector } from 'store/hero/selectors';

const CircularFarm = () => {
  const path = useZustandHeroStore(getHeroPathSelector);

  const hero = useZustandHeroStore(getHeroIdSelector);

  return (
    <div className={styles.circle}>
      <div className={styles.shadow} />

      <img
        src={hero ? path : '/icons-hamster/heroes/hero-1.png'}
        className={styles.hamster}
      />
    </div>
  );
};

export default CircularFarm;
