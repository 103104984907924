export const RULES = [
  {
    title: 'rules.1',
    options: ['rules.1_0'],
  },
  {
    title: 'rules.2',
    options: ['rules.2_0', 'rules.2_1', 'rules.2_2'],
  },
  {
    title: 'rules.3',
    options: ['rules.3_0', 'rules.3_1', 'rules.3_2'],
  },
  {
    title: 'rules.4',
    options: ['rules.4_0', 'rules.4_1', 'rules.4_2'],
  },
  {
    title: 'rules.5',
    options: [
      'rules.5_0',
      'rules.5_1',
      'rules.5_2',
      'rules.5_3',
      'rules.5_4',
      'rules.5_5',
      'rules.5_6',
    ],
  },
];
