import { ReactComponent as Craft } from 'assets/mine.svg';
import { ReactComponent as Earn } from 'assets/earn.svg';
import { ReactComponent as Airdrop } from 'assets/coin.svg';
import { ReactComponent as MenuSvg } from 'assets/menu.svg';

import { ERouteNames } from 'routes/types';

export const NAV_BAR = [
  {
    icon: <Airdrop />,
    title: 'Earn',
    path: ERouteNames.Home,
  },
  {
    icon: <Craft />,
    title: 'Shop',
    path: ERouteNames.Craft,
  },
  {
    icon: <Earn />,
    title: 'exchange',
    path: ERouteNames.Change,
  },
  {
    icon: <MenuSvg />,
    title: 'Rules',
    path: ERouteNames.Rules,
  },
];
