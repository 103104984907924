export enum EMessageType {
  IsTapped = 'isTapped',
  InitValues = 'init-values',
  InitValuesStatus = 'init-values-status',
  Balance = 'balance',
  Energy = 'energy',
  Theme = 'theme',
  ThemeStatus = 'theme-status',
  BoostEnergy = 'boost-energy',
  ChangeCoins = 'change-coins',
  GetStoreLinks = 'get-store-links',
  StoreData = 'store-data',
  BuyAvatarItem = 'buy-avatar-item',
  BuyHeroItem = 'buy-hero-item',
}
